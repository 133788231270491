.no-wrap-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.transition {
  @include transition(all 0.4s);
}

.whole-page-error {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #df0000;
}

.info-message {
  color: var(--p-icon-highlight);
}

.discounted-price {
  text-decoration: line-through;
  display: block;
  text-align: left;
}

.subscription-clickable-row {
  border-top: 1px solid #e1e3e5;
  cursor: pointer;
  transition: all 0.4s;
  &:hover {
    background-color: rgb(246, 246, 247);
  }
}

.Polaris-Frame {
  padding-bottom: 20px;
}

.subscription-line-price-wrapper {
  text-align: right;
  height: 100%;
}

.remove-icon-inline-spacing .Polaris-Icon {
  margin: 0;
}

.edit-subscription-modal .Polaris-Box,
.edit-subscription-modal .Polaris-ShadowBevel {
  box-shadow: unset !important;
  border: unset;
}
.edit-subscription-modal .Polaris-ShadowBevel::before {
  display: none !important;
}

.product-row {
  cursor: pointer;
  transition: 0.2s;
  &:not(:last-child) {
    border-bottom: 1px solid #ebebeb;
  }
  &:hover {
    background: #f7f7f7;
  }
}

@media screen and (max-width: 400px) {
  .subscription-line-wrapper > .Polaris-Stack > .Polaris-Stack__Item:last-child {
    flex: 0 0 100%;
  }
  .subscription-line-price-wrapper .Polaris-Stack {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 16px;
  }
}
