.otp-container {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  .otp-form-container {
    margin: 0 auto;
    width: 380px;
    .Polaris-TextField__Spinner {
      display: none;
    }
    .Polaris-TextField > input {
      padding: 0.5rem;
      text-align: center;
      width: 43px;
    }
  }
}

@media screen and (max-width: 576px) {
  .otp-container {
    .otp-form-container {
      width: 100%;
    }
  }
}
