.ck-editor-parser {
  h2,
  h3,
  h4 {
    font-weight: bold;
  }
  h2 {
    font-size: 22px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 18px;
  }
}
