.chevron-linked-card {
  .Polaris-Link {
    width: 100%;
  }
  a {
    text-decoration: none !important;
    h2 {
      color: rgb(32, 34, 35) !important;
    }
  }
}
