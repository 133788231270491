.feature-style-link {
  border-radius: 5px;
  padding: 10px;
  @include transition(all 0.4s);
  .Polaris-Thumbnail {
    background: #f4f6f8;
    border: none;
    transition: all 0.4s;
  }
  &:hover {
    background-color: #f9fafb;
    .Polaris-Thumbnail {
      background: #dfe3e8;
    }
  }
}
